<template>
    <div class="row">
        <div v-for="(val, key) in objectData" :key="key" class="col-6 section">
            <h3 v-if="typeof val === 'object'" class="section-title" :class="getSectionClass(level)">{{ key }}
                <mercur-button @click.native="openModal(label, key)" class="btn btn-icon btn-yellow text-white">
                    <i class="fas fa-plus"></i>
                </mercur-button>
                <mercur-button @click.native="removeKey(label, key)" class="btn btn-icon btn-yellow text-white">
                    <i class="fas fa-minus"></i>
                </mercur-button>
            </h3>
            <div class="d-flex align-items-end mb-3" v-if="typeof val !== 'object'">
                <mercur-input class="mb-0 flex-grow-1" v-model="objectData[key]">{{ key }}</mercur-input>
                <mercur-button class="btn btn-icon btn-icon-square btn-yellow text-white" @click.native="removeKey(label, key)"><i class="fas fa-minus"></i></mercur-button>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <recursive-input :objectData="objectData[key]" :label="`${label} ${key}`" :level="level + 1" :id="id" class="not-first"></recursive-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'RecursiveInput',
    props: {
        objectData: {
            default: null,
        },
        label: {
            default: '',
        },
        level: {
            default: 0,
        },
        id: {
            default: null,
        },
    },
    methods: {
        getSectionClass (level) {
            return `section-title--${level}`
        },
        openModal (label, key) {
            const data = {
                label: label,
                key: key,
                id: this.id,
            }
            this.$bus.$emit('editConfig', data)
        },
        removeKey (label, key) {
            const data = {
                label: label,
                key: key,
                id: this.id,
            }
            this.$bus.$emit('removeKey', data)
        },
    },
}
</script>

<style lang="scss" scoped>
    .label {
        text-transform: none;
    }
    .section {
        margin-bottom: 30px;
        padding-left: 40px !important;
    }
    .section-title {
        margin-top: 10px;
    }
    .not-first {
        .col-6 {
            min-width: 100%;
            max-width: 100%;
            flex: 1 1;
        }

        .section {
            margin-bottom: 0;
        }
    }
    .remove-icon {
        cursor: pointer;
        color: #ffc628 !important;
    }
</style>
