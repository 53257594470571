<template>
    <div class="pb-5">
        <template v-if="hasPermission('MerchantCentral/listShopConfig')">
            <mercur-card class="mb-4" v-for="(config, index) in configs" :key="index">
                <h3>{{ config.configurationName }}
                    <mercur-button @click.native="editConfig({id: config.configurationId})" class="btn btn-icon btn-yellow text-white" v-if="hasPermission('MerchantCentral/updateShopConfig')">
                        <i class="fas fa-plus"></i>
                    </mercur-button>
                    <mercur-button @click.native="openConfirmDialog({id: config.configurationId, key: config.configurationName}, removeConfig)" class="btn btn-icon btn-yellow text-white" v-if="hasPermission('MerchantCentral/removeShopConfig')">
                        <i class="fas fa-minus"></i>
                    </mercur-button>
                </h3>
                <hr>
                <form  @submit.prevent="submitForm(config.configurationName, config.configurationId, config.configurationData)">
                    <recursive-input :objectData="config.configurationData" :id="config.configurationId"></recursive-input>
                    <br>
                    <mercur-button class="btn btn-primary" type="submit" :disabled="isDisabled(config.configurationId)">Save</mercur-button>
                </form>
            </mercur-card>

            <div>
                <mercur-button v-if="hasPermission('MerchantCentral/createShopConfig')" class="btn btn-primary" @click.native="addConfig">ADD NEW CONFIG</mercur-button>
            </div>
        </template>
        <template v-else>
            Not allowed to see this view
        </template>

       <div>
           <mercur-dialog :is-open.sync="isDialogOpen">
               <div v-if="!this.dialogData.key || !this.dialogData.label">
                   <p>Do you want to add an object?</p>
                   <mercur-radio :value="true" v-model="isObject">YES</mercur-radio>
                   <mercur-radio :value="false" v-if="!isAddingConfig" v-model="isObject">NO</mercur-radio>
               </div>

               <div v-if="!isObject">
                   <p>Add key and value for configuration</p>
                   <form @submit.prevent="addConfigKey">
                       <mercur-input v-model="newConfigItem.itemKey" id="configKey">Add key</mercur-input>
                       <mercur-input v-model="newConfigItem.itemValue" id="configKey">Add value</mercur-input>
                       <mercur-button type="submit" class="btn btn-primary">Add</mercur-button>
                   </form>
               </div>

               <div v-else>
                   <form @submit.prevent="addConfigKey">
                       <mercur-input v-model="newConfigItem.itemKey" id="configKey">Add key</mercur-input>
                       <mercur-button type="submit" class="btn btn-primary" :disabled="isCreatingConfig">Add</mercur-button>
                   </form>
               </div>
           </mercur-dialog>

           <mercur-dialog :is-open.sync="isConfirmOpen">
               <h3 class="font-weight-normal">{{ tempData.data.key }}</h3>
               <p>Are you sure you want to remove this configuration?</p>
               <template #footer>
                   <mercur-button class="btn" @click.native="isConfirmOpen = false">Cancel</mercur-button>
                   <mercur-button class="btn btn-primary" @click.native="tempData.method(tempData.data)">Confirm</mercur-button>
               </template>
           </mercur-dialog>
       </div>

    </div>
</template>

<script>
import RecursiveInput from '@/components/elements/RecursiveInput'
import CONFIG from '@root/config'
import { mapActions } from 'vuex'
import FormMixin from '@/mixins/Form'
let fetchingMethod, removingMethod

export default {
    name: 'ShopConfigDisplay',
    components: { RecursiveInput },
    mixins: [FormMixin],
    props: {
        category: {
            required: true,
        },
    },
    data () {
        return {
            action: CONFIG.API.ROUTES.SHOP_CONFIG.UPDATE,
            disabledButtons: [],
            isDialogOpen: false,
            dialogData: {},
            isObject: false,
            isAddingConfig: false,
            isCreatingConfig: false,
            isConfirmOpen: false,
            tempData: {
                data: {},
                method: null,
            },
            newConfigItem: {
                itemKey: '',
                itemValue: '',
            },
        }
    },
    computed: {
        configs () {
            return this.$store.getters['shopconfig/shopConfig'](this.category)
        },
    },
    methods: {
        ...mapActions('shopconfig', ['fetchShopConfig', 'updateConfig', 'createShopConfig', 'removeConfigKey', 'removeShopConfig']),
        submitForm (name, id, payload) {
            this.disabledButtons.push(id)
            const form = {
                configurationId: id,
                configurationName: name,
                configurationData: payload,
                configurationCategory: this.category,
            }
            this.submit(this.action, form).then((data) => {
                this.disabledButtons = this.disabledButtons.filter((x) => x !== id)
                this.$root.$emit('notification:global', {
                    message: 'Shop Config was updated',
                })
            })
        },
        isDisabled (index) {
            if (this.disabledButtons.find((x) => x === index)) {
                return true
            }
            return false
        },
        addConfig () {
            this.isObject = true
            this.isAddingConfig = true
            this.isDialogOpen = true
        },
        editConfig (data) {
            this.dialogData = data
            this.isDialogOpen = true
            this.isAddingConfig = false
        },
        openConfirmDialog (data, method) {
            this.tempData.data = data
            this.tempData.method = method
            this.isConfirmOpen = true
        },
        removeConfig (data) {
            this.removeShopConfig({
                id: data.id,
                category: this.category,
            })
            this.isConfirmOpen = false
        },
        removeKey (data) {
            this.removeConfigKey({ ...data, category: this.category })
            this.isConfirmOpen = false
        },
        addConfigKey () {
            if (this.isAddingConfig) {
                this.isCreatingConfig = true
                this.createShopConfig({
                    configurationId: this.$uuid.v4(),
                    configurationName: this.newConfigItem.itemKey,
                    configurationCategory: this.category,
                }).then(() => {
                    this.reset()
                    this.isCreatingConfig = false
                })
                return
            }
            const keys = []
            if (this.dialogData.label) {
                keys.push(this.dialogData.label)
            }
            if (this.dialogData.key) {
                keys.push(this.dialogData.key)
            }
            this.updateConfig({
                id: this.dialogData.id,
                category: this.category,
                keys: keys,
                ...this.newConfigItem,
            })
            this.reset()
        },
        reset () {
            this.dialogData = {}
            this.isObject = false
            this.newConfigItem = {
                itemKey: '',
                itemValue: '',
            }
            this.isAddingConfig = false
            this.isDialogOpen = false
        },
    },
    created () {
        if (!this.hasPermission('listShopConfig')) {
            return
        }
        this.fetchShopConfig({
            category: this.category,
        })
    },
    mounted () {
        this.$bus.$on('editConfig', this.editConfig)
        this.$bus.$on('removeKey', removingMethod = (data) => {
            this.openConfirmDialog(data, this.removeKey)
        })
        this.$bus.$on('shopWasChanged', fetchingMethod = () => {
            if (this.selectedShop.applicationId === '0') {
                return
            }

            this.fetchShopConfig({
                category: this.category,
            })
        })
    },
    destroyed () {
        this.$bus.$off('editConfig', this.editConfig)
        this.$bus.$off('removeKey', removingMethod)
        this.$bus.$off('shopWasChanged', fetchingMethod)
    },
}
</script>

<style lang="scss" scoped>
    .config-dialog {
        min-width: 500px;
    }

    h3 {
        font-weight: bold;
        &:after {
            display: none;
        }
    }
</style>
